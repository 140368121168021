import React from "react"
import PropTypes from "prop-types"

import LivreElectroniqueCallToAction from "../../boutique/fr/LivreElectroniqueCallToAction.js"
import LayoutFR from "./LayoutFR"
import PourAllerPlusLoin from "../../navigation/PourAllerPlusLoin"

export default function LayoutTutorielFR(props) {
  return (
    <LayoutFR page={props.page}>
      {props.children}
      <LivreElectroniqueCallToAction page={props.page} />
      <PourAllerPlusLoin
        page={props.page}
        pagesPourAllerPlusLoin={props.pagesPourAllerPlusLoin}
      />
    </LayoutFR>
  )
}

LayoutTutorielFR.propTypes = {
  children: PropTypes.array.isRequired,
  page: PropTypes.object,
  pagesPourAllerPlusLoin: PropTypes.array,
}

import React from "react"
import PropTypes from "prop-types"

import Bouton from "./Bouton"

export default function BoutonContinuer({
  texte,
  codePageCible,
  lienExterne,
  classeCSS,
}) {
  return (
    <Bouton
      texte={texte}
      codePageCible={codePageCible}
      lienExterne={lienExterne}
      classeCSS={classeCSS}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="24"
        viewBox="0 0 24 24"
        width="24"
      >
        <path d="M0 0h24v24H0z" fill="none" />
        <path d="M12 8V4l8 8-8 8v-4H4V8z" />
      </svg>
    </Bouton>
  )
}

BoutonContinuer.propTypes = {
  texte: PropTypes.string,
  codePageCible: PropTypes.string,
  lienExterne: PropTypes.bool,
  classeCSS: PropTypes.string,
}
